@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply smooth scrolling to all scrollable elements */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  scroll-behavior: smooth;
  transition: all ease .5s;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


